<template>
  <ReplySearch ref="hello" @getInfo="getSearchInfo" @changeTableHight="changeSerchStype" />
  <div class="middle-cont"></div>
  <div class="replyTable">
    <el-table
      header-row-class-name="replayTableHeader"
      row-class-name="replyTable-row-reply"
      :data="data.commentList"
      :max-height="data.tableHeight"
      style="width: 100%"
      v-loading="data.tableLoading"
    >
      <el-table-column prop="commentId" label="回复ID" align="center" width="100"> </el-table-column>
      <el-table-column prop="postContent" label="回复源帖子">
        <template #default="scope">
          <div @click="openReaplyTempDailog(scope.row.postId)">
            <div>
              <div class="replyTable-postContent-container-reply">
                <span>
                  {{ clearHtml(scope.row.postTitle) }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="commentContent" label="回复内容">
        <template #default="scope">
          <el-tooltip
            popper-class="el-popper-reply"
            effect="light"
            placement="bottom"
            :content="scope.row.commentContent"
            v-if="clearCode(scope.row.commentContent).length > 20"
          >
            <div class="replyTable-postContent-container-reply">
              <span>
                {{ replyMessage(scope.row.commentContent) }}
              </span>
            </div>
          </el-tooltip>
          <div v-else>
            <div class="replyTable-postContent-container-reply">
              <span>
                {{ replyMessage(scope.row.commentContent) }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createNickName" label="发表者">
        <template #default="scope">
          <el-tooltip
            popper-class="el-popper-reply"
            :content="scope.row.createNickName"
            placement="bottom"
            effect="light"
            v-if="scope.row.createNickName.length > 9">
            <div class="replyTable-img-container"  @click="showUseInfo(scope.row)">
              <img :src="scope.row.createIcon">
              <span class="container-ellipsis">
                {{scope.row.createNickName}}
              </span>
            </div>
          </el-tooltip>
          <div v-else class="replyTable-img-container" @click="showUseInfo(scope.row)">
            <img :src="scope.row.createIcon" />
            <span>
              {{ scope.row.createNickName }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="relDate" label="发表时间">
        <template #default="scope">
          <div style="width: 82px">
            <span>{{ relDateFormat(scope.row.createTimestamp) }}</span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="relPv"
        label="浏览PV">
      </el-table-column> -->
      <el-table-column prop="replyNum" label="回复量">
        <template #default="scope">
          <div style="width: 82px">
            <span>{{ numberThousand(scope.row.replyNum) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="likeNum" label="点赞量">
        <template #default="scope">
          <div style="width: 82px">
            <span>{{ numberThousand(scope.row.likeNum) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="verifyStatus" label="审核状态">
        <template #default="scope">
          <div :class="approvalState(scope.row.verifyStatus).classText">
            {{ approvalState(scope.row.verifyStatus).text }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" width="100">
        <template #default="scope">
          <el-button
            type="text"
            :style="addressColor(scope.row.verifyStatus, data.userInfo.roleId).style"
            @click="clickDeleteBtn(scope.row.commentId)"
            :disabled="addressColor(scope.row.verifyStatus, data.userInfo.roleId).disable"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="reply-pagination">
    <el-pagination
      style="float: right"
      @size-change="handleSizeChangeFn"
      @current-change="handleCurrentChange"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="data.pageInfo.pageSize"
      layout="sizes, prev, pager, next"
      :current-page="data.pageInfo.pageNum"
      :total="data.pageInfo.total"
    >
    </el-pagination>
  </div>
  <div class="reply-dialog">
    <el-dialog title="删除" width="420px" top="35vh" v-model="data.dialogTableVisible">
      <el-alert title="确定要进行删除操作吗？" :closable="false" class="dialog-alert-bg" type="warning" show-icon>
      </el-alert>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="data.dialogTableVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="deleteTableData">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <div>
    <el-dialog width="420px" v-model="data.reaplyTempDailogVisible" destroy-on-close>
      <IframeContainer />
    </el-dialog>
  </div>
  <SenderTemplate />
</template>
<script lang="ts">
import ReplySearch from '../common/ReplySearch.vue';
import SenderTemplate from '../common/SenderTemplate.vue';
import IframeContainer from '../common/IframTem.vue';
import { defineComponent, reactive, onMounted, provide, computed } from 'vue';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
import { getReplyTable, deleteTableDataApi } from '../../reactivity/getReplyData';
// import { numberFormat } from '../../utils/numberFromat';
import { numberThousand, replyMessage } from '../../utils/numberFromat';
import { searchInfoType } from '../../../types/reply';
import { approvalState } from '../../utils/index';
import { useStore } from 'vuex';
import { clearHtml } from '@/utils/getTimeToUnix';
interface rowInfoType {
  commentId: number
  commentContent: string
  postContent: string
  createNickName: string
  createIcon: string
  createTimestamp: number
  replyNum: number
  likeNum: number
  verifyStatus: number
  createWx: number
  createQQ: number
  createLoginType: number
  createUserId: string
}

export default defineComponent({
  components: {
    ReplySearch,
    SenderTemplate,
    IframeContainer,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      // 页面数据
      dialogTableVisible: false, // 删除提醒弹窗
      reaplyTempDailogVisible: false, // 帖子解构预览弹窗
      senderInfoDailogVisible: false, // 发表者信息弹窗
      commentId: 0,
      needOpenId: 0,
      verifyStatus: 4,
      tableLoading: false,
      verifyStateOptions: [
        { label: '全部', value: 0 },
        { label: '审核通过', value: 4 },
        { label: '审核未通过', value: 5 },
      ],
      iframeUrl: '',
      commentList: [],
      searchInfo: {},
      senderInfo: {
        createIcon: '', // 发表者头像
        createNickName: '', // 发表者昵称
        createWx: '', // 发表者微信
        createQQ: '', // 发表者QQ
      },
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
      },
      tableHeight: 300,
      showAll: true, // 是否展开或者折叠
      userInfo: computed(() => store.getters.userInfo),
    });
    provide('selectOptions', data.verifyStateOptions);
    provide('verifyStatus', data.verifyStatus);
    provide('senderInfoDailogVisible', data);
    provide('needIframeData', data);
    const addressColor = (state: number, roleId: number) => {
      // 操作删除时候禁用
      const obj = {
        style: 'color: #C0C4CC;font-size: 14px',
        disable: true,
      };
      if (roleId === 1 || roleId === 3) {
        switch (state) {
          case 6:
            obj.style = 'color: #C0C4CC;font-size: 14px';
            obj.disable = true;
            return obj;
          default:
            obj.style = 'color: #F56C6C;font-size: 14px';
            obj.disable = false;
            return obj;
        }
      }
      return obj;
    };
    const relDateFormat = (num: number) => dayjs(num * 1000).format('YYYY-MM-DD HH:mm:ss'); // 时间戳格式化
    const showUseInfo = (rowInfo: rowInfoType) => {
      data.senderInfo.createIcon = rowInfo.createIcon;
      data.senderInfo.createNickName = rowInfo.createNickName;
      data.senderInfo.createWx = rowInfo.createLoginType === 2 ? rowInfo.createUserId.toString() : '/';
      data.senderInfo.createQQ = rowInfo.createLoginType === 1 ? rowInfo.createUserId.toString() : '/';
      data.senderInfoDailogVisible = true;
    };
    const clearCode = (test: string): string => test.replace(/[\r\n]/g, '');
    const getSearchInfo = (obj: searchInfoType) => {
      // 传到子组件让子组件调用搜索功能
      data.searchInfo = obj;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    const openReaplyTempDailog = (id: number) => {
      // 预览H5帖子弹窗
      data.needOpenId = id;
      data.reaplyTempDailogVisible = true;
    };
    const clickDeleteBtn = (id: number) => {
      data.commentId = id;
      data.dialogTableVisible = true;
    };
    const deleteTableData = () => {
      // 删除按钮Fn
      deleteTableDataApi({ commentId: data.commentId })
        .then((res) => {
          if (res.data?.ret === 0) {
            ElMessage.success({
              message: '删除成功',
              type: 'success',
            });
            handleSearch();
          }
        })
        .finally(() => {
          data.dialogTableVisible = false;
        });
    };
    const getTableData = (req: searchInfoType) => {
      data.tableLoading = true;
      // 初始化获取表格数据
      getReplyTable(req).then((res) => {
        if (res.data?.ret === 0) {
          const { commentData, pageInfo } = res.data.data;
          data.commentList = commentData;
          data.pageInfo = pageInfo;
        } else {
          console.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.tableLoading = false;
        });
    };
    window.onresize = () => (() => {
      // 监听窗口高度,进行table高度控制
      changeTableMaxHeight();
    })();
    const changeTableMaxHeight = () => {
      // 改变表格最高高度
      data.tableHeight = document.body.clientHeight - 56 - (data.showAll ? 245 : 112) - 24 - 100;
    };
    changeTableMaxHeight(); // 初始化显示表格高度
    onMounted(() => {
      // 生命钩子
      handleSearch(); // 初始化获取表格数据
    });
    const handleCurrentChange = (num: number) => {
      // 改变页数
      data.pageInfo.pageNum = num;
      handleSearch();
    };
    const handleSizeChangeFn = (num: number) => {
      // 改变每页显示条数
      data.pageInfo.pageSize = num;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    const changeSerchStype = (isAll: boolean) => {
      // 收起/展开按钮,并且重置table高度
      data.showAll = isAll;
      changeTableMaxHeight();
    };
    const handleSearch = () => {
      // 搜索按钮FN
      const searchInfo: searchInfoType = {
        // 搜索数据
        verifyStatus: 4,
        ...data.searchInfo,
        pageNum: data.pageInfo.pageNum,
        pageSize: data.pageInfo.pageSize,
      };
      Object.keys(searchInfo).forEach((k: string) => {
        // 处理搜索入参
        if (!searchInfo[k] && k !== 'verifyStatus') {
          delete searchInfo[k];
        } else {
          if (
            k !== 'sendDate'
            && k !== 'createWx'
            && k !== 'createQQ'
            && k !== 'createNickName'
            && k !== 'communityName'
          ) {
            searchInfo[k] = Number(searchInfo[k]);
          }
        }
        if (k === 'sendDate' && searchInfo.sendDate) {
          if (searchInfo.sendDate.length > 0) {
            Object.assign(searchInfo, {
              createBeginTimestamp: dayjs(searchInfo.sendDate[0].toString()).unix(),
              createEndTimestamp: dayjs(searchInfo.sendDate[1].toString()).unix(),
            });
          }
          delete searchInfo[k];
        }
      });
      getTableData(searchInfo);
    };
    const clearSearchInfoFn = () => {
      // 重置按钮FN
      data.searchInfo = {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: 4, // 审核状态
      };
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    return {
      handleSearch, // 检索按钮FN
      changeSerchStype, // 收起展开FN
      clearSearchInfoFn, // 重置FN
      data, // 页面总数据
      handleCurrentChange, // 分页改变当前页
      handleSizeChangeFn, // 每页显示条数
      deleteTableData, // 弹框确认删除的按钮FN
      clickDeleteBtn, // table删除按钮FN
      openReaplyTempDailog, // 打开回复内容布局弹窗
      approvalState,
      relDateFormat, // 发表时间格式化
      showUseInfo,
      getSearchInfo, // 获取搜索功能子组件的信息 父-->子
      addressColor, // 操作删除时候禁用
      // numberFormat,
      numberThousand,
      clearHtml,
      clearCode,
      replyMessage,
    };
  },
});
</script>
<style lang="scss" scoped src="./index.scss">
</style>
