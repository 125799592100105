
import ReplySearch from '../common/ReplySearch.vue';
import SenderTemplate from '../common/SenderTemplate.vue';
import IframeContainer from '../common/IframTem.vue';
import { defineComponent, reactive, onMounted, provide, computed } from 'vue';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
import { getReplyTable, deleteTableDataApi } from '../../reactivity/getReplyData';
// import { numberFormat } from '../../utils/numberFromat';
import { numberThousand, replyMessage } from '../../utils/numberFromat';
import { searchInfoType } from '../../../types/reply';
import { approvalState } from '../../utils/index';
import { useStore } from 'vuex';
import { clearHtml } from '@/utils/getTimeToUnix';
interface rowInfoType {
  commentId: number
  commentContent: string
  postContent: string
  createNickName: string
  createIcon: string
  createTimestamp: number
  replyNum: number
  likeNum: number
  verifyStatus: number
  createWx: number
  createQQ: number
  createLoginType: number
  createUserId: string
}

export default defineComponent({
  components: {
    ReplySearch,
    SenderTemplate,
    IframeContainer,
  },
  setup() {
    const store = useStore();
    const data = reactive({
      // 页面数据
      dialogTableVisible: false, // 删除提醒弹窗
      reaplyTempDailogVisible: false, // 帖子解构预览弹窗
      senderInfoDailogVisible: false, // 发表者信息弹窗
      commentId: 0,
      needOpenId: 0,
      verifyStatus: 4,
      tableLoading: false,
      verifyStateOptions: [
        { label: '全部', value: 0 },
        { label: '审核通过', value: 4 },
        { label: '审核未通过', value: 5 },
      ],
      iframeUrl: '',
      commentList: [],
      searchInfo: {},
      senderInfo: {
        createIcon: '', // 发表者头像
        createNickName: '', // 发表者昵称
        createWx: '', // 发表者微信
        createQQ: '', // 发表者QQ
      },
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
      },
      tableHeight: 300,
      showAll: true, // 是否展开或者折叠
      userInfo: computed(() => store.getters.userInfo),
    });
    provide('selectOptions', data.verifyStateOptions);
    provide('verifyStatus', data.verifyStatus);
    provide('senderInfoDailogVisible', data);
    provide('needIframeData', data);
    const addressColor = (state: number, roleId: number) => {
      // 操作删除时候禁用
      const obj = {
        style: 'color: #C0C4CC;font-size: 14px',
        disable: true,
      };
      if (roleId === 1 || roleId === 3) {
        switch (state) {
          case 6:
            obj.style = 'color: #C0C4CC;font-size: 14px';
            obj.disable = true;
            return obj;
          default:
            obj.style = 'color: #F56C6C;font-size: 14px';
            obj.disable = false;
            return obj;
        }
      }
      return obj;
    };
    const relDateFormat = (num: number) => dayjs(num * 1000).format('YYYY-MM-DD HH:mm:ss'); // 时间戳格式化
    const showUseInfo = (rowInfo: rowInfoType) => {
      data.senderInfo.createIcon = rowInfo.createIcon;
      data.senderInfo.createNickName = rowInfo.createNickName;
      data.senderInfo.createWx = rowInfo.createLoginType === 2 ? rowInfo.createUserId.toString() : '/';
      data.senderInfo.createQQ = rowInfo.createLoginType === 1 ? rowInfo.createUserId.toString() : '/';
      data.senderInfoDailogVisible = true;
    };
    const clearCode = (test: string): string => test.replace(/[\r\n]/g, '');
    const getSearchInfo = (obj: searchInfoType) => {
      // 传到子组件让子组件调用搜索功能
      data.searchInfo = obj;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    const openReaplyTempDailog = (id: number) => {
      // 预览H5帖子弹窗
      data.needOpenId = id;
      data.reaplyTempDailogVisible = true;
    };
    const clickDeleteBtn = (id: number) => {
      data.commentId = id;
      data.dialogTableVisible = true;
    };
    const deleteTableData = () => {
      // 删除按钮Fn
      deleteTableDataApi({ commentId: data.commentId })
        .then((res) => {
          if (res.data?.ret === 0) {
            ElMessage.success({
              message: '删除成功',
              type: 'success',
            });
            handleSearch();
          }
        })
        .finally(() => {
          data.dialogTableVisible = false;
        });
    };
    const getTableData = (req: searchInfoType) => {
      data.tableLoading = true;
      // 初始化获取表格数据
      getReplyTable(req).then((res) => {
        if (res.data?.ret === 0) {
          const { commentData, pageInfo } = res.data.data;
          data.commentList = commentData;
          data.pageInfo = pageInfo;
        } else {
          console.error(res.data?.msg);
        }
      })
        .finally(() => {
          data.tableLoading = false;
        });
    };
    window.onresize = () => (() => {
      // 监听窗口高度,进行table高度控制
      changeTableMaxHeight();
    })();
    const changeTableMaxHeight = () => {
      // 改变表格最高高度
      data.tableHeight = document.body.clientHeight - 56 - (data.showAll ? 245 : 112) - 24 - 100;
    };
    changeTableMaxHeight(); // 初始化显示表格高度
    onMounted(() => {
      // 生命钩子
      handleSearch(); // 初始化获取表格数据
    });
    const handleCurrentChange = (num: number) => {
      // 改变页数
      data.pageInfo.pageNum = num;
      handleSearch();
    };
    const handleSizeChangeFn = (num: number) => {
      // 改变每页显示条数
      data.pageInfo.pageSize = num;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    const changeSerchStype = (isAll: boolean) => {
      // 收起/展开按钮,并且重置table高度
      data.showAll = isAll;
      changeTableMaxHeight();
    };
    const handleSearch = () => {
      // 搜索按钮FN
      const searchInfo: searchInfoType = {
        // 搜索数据
        verifyStatus: 4,
        ...data.searchInfo,
        pageNum: data.pageInfo.pageNum,
        pageSize: data.pageInfo.pageSize,
      };
      Object.keys(searchInfo).forEach((k: string) => {
        // 处理搜索入参
        if (!searchInfo[k] && k !== 'verifyStatus') {
          delete searchInfo[k];
        } else {
          if (
            k !== 'sendDate'
            && k !== 'createWx'
            && k !== 'createQQ'
            && k !== 'createNickName'
            && k !== 'communityName'
          ) {
            searchInfo[k] = Number(searchInfo[k]);
          }
        }
        if (k === 'sendDate' && searchInfo.sendDate) {
          if (searchInfo.sendDate.length > 0) {
            Object.assign(searchInfo, {
              createBeginTimestamp: dayjs(searchInfo.sendDate[0].toString()).unix(),
              createEndTimestamp: dayjs(searchInfo.sendDate[1].toString()).unix(),
            });
          }
          delete searchInfo[k];
        }
      });
      getTableData(searchInfo);
    };
    const clearSearchInfoFn = () => {
      // 重置按钮FN
      data.searchInfo = {
        postId: '', // 搜索条件回帖ID
        createNickName: '', // 搜索条件发表者昵称
        createQQ: '', // 搜索条件发表者QQ
        createWx: '', // 搜索条件发表者微信
        communityName: '', // 搜索条件所属社区
        commentId: '', // 搜索条件回复ID
        sendDate: [], // 搜索条件发表时间
        verifyStatus: 4, // 审核状态
      };
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    return {
      handleSearch, // 检索按钮FN
      changeSerchStype, // 收起展开FN
      clearSearchInfoFn, // 重置FN
      data, // 页面总数据
      handleCurrentChange, // 分页改变当前页
      handleSizeChangeFn, // 每页显示条数
      deleteTableData, // 弹框确认删除的按钮FN
      clickDeleteBtn, // table删除按钮FN
      openReaplyTempDailog, // 打开回复内容布局弹窗
      approvalState,
      relDateFormat, // 发表时间格式化
      showUseInfo,
      getSearchInfo, // 获取搜索功能子组件的信息 父-->子
      addressColor, // 操作删除时候禁用
      // numberFormat,
      numberThousand,
      clearHtml,
      clearCode,
      replyMessage,
    };
  },
});
